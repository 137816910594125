/*
 * @license
 * Copyright© Celligence International, LLC - 2023
 *
 * This software program is protected by copyright laws and is the property of
 * Celligence International, LLC (Celligence). Any unauthorized reproduction,
 * distribution, modification, or public display of this program, without the
 * explicit permission of Celligence is strictly prohibited. If you have gained
 * access to this program without the expressed approval of Celligence report
 * the incident to legal@celligence.com.
 *
 */
import React, { useEffect, useState, lazy, Suspense } from "react";
import "./App.css";
import Image from "react-bootstrap/Image";
import "@fontsource/poppins";
import HeaderNavbar from "./components/Header/index.js";
import Footer from "./components/CellFooter/index.js";
import { Spinner } from "react-bootstrap";
import axios from "axios";
import { Helmet } from "react-helmet";
const RigvedQuotes = lazy(() => import("./components/RigvedQuotes/index.js"));
const Disclaimer = lazy(() => import("./components/Disclaimer/index.js"));
const Body = lazy(() => import("./components/Body/Body.js"));
const RealtorCarousel = lazy(() => import("./components/RealtorCarousel"));
const TruApproval = lazy(() =>
  import("./components/TruApprovalAngelAi/TruApproval.js")
);
const AngelAi = lazy(() =>
  import("./components/TruApprovalAngelAi/AngelAi.js")
);
const ProductSuite = lazy(() =>
  import("./components/ProductSuiteUltraFastSaving/ProductSuite.js")
);
const UltraFastSaving = lazy(() =>
  import("./components/ProductSuiteUltraFastSaving/UltraFastSaving.js")
);
const LoanOfficer = lazy(() =>
  import("./components/LoanOfficer/LoanOfficer.js")
);

function HomePage() {
  const [loading, setLoading] = useState(true);
  const [showRigVedQuotes, setShowRigVedQuotes] = useState(false);
  const [aiBrandingData, setAiBrandingData] = useState();
  const [angelAiUserInfo, setAngelAiUserInfo] = useState();
  const [showOtherComponents, setShowOtherComponents] = useState(false);

  const handleShowOtherComponents =  () => setShowOtherComponents(true);

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.body.offsetHeight;
      const scrollPosition =
        window.scrollY ||
        window.pageYOffset ||
        document.documentElement.scrollTop;
      const maxScroll = documentHeight - windowHeight;
      const percentage = (scrollPosition / maxScroll) * 100;

      if (percentage > 1) {
        handleShowOtherComponents();
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    /**
     * @author Pratik Kajrolkar
     * @date 07/20/2023
     * The below function is used to fetch the ai-branding.json data for AI Branding.
     * @returns JSON Object of the requested file
     */
    const fetchAiBrandingJson = async () => {
      try {
        await axios
          .get(process.env.REACT_APP_ANGELAI_JSON)
          .then((response) => {
            // console.log(JSON.stringify(response.data));
            setAiBrandingData(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (e) {
        console.log(e);
      }
    };

    /**
     * Fetches the AngelAi user info from the specified endpoint.
     *
     * @return {Promise<void>} - A Promise that resolves when the user info has been fetched successfully.
     */
    const fetchAngelAiUserInfo = async () => {
      try {
        await axios
          .get(process.env.REACT_APP_ANGEL_AI_WEB_USER_INFO)
          .then((response) => {
            // console.log(JSON.stringify(response.data));
            setAngelAiUserInfo(response.data);
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (e) {
        console.log(e);
      }
    };

    /**
     * @author Pratik Kajrolkar
     * @date 07/20/2023
     * The below function is used to get data required to show on home page /agent.
     * @returns JSON Object of the requested file
     */
    const fetchHomePageDetails = async () => {
      try {
        await fetchAiBrandingJson();
        await fetchAngelAiUserInfo();
        setLoading(false);
      } catch (e) {
        console.log(e);
      }
    };

    fetchHomePageDetails();
  }, []);



  return (
    <div className='homepage'>
      <Helmet>
        <link rel="canonical" href="https://www.angelai.com/agent/" />
      </Helmet>
      <div className="bodyContainer">
        {loading ? (
          <Spinner animation="border" />
        ) : (
          <>
            {aiBrandingData ? (
              <>
                <HeaderNavbar
                handleShowOtherComponents={handleShowOtherComponents}
                  aiBrandingData={aiBrandingData}
                  setShowRigVedQuotes={setShowRigVedQuotes}
                />
                <Suspense fallback={<div />}>
                  <RigvedQuotes
                    showRigVedQuotes={showRigVedQuotes}
                    setShowRigVedQuotes={setShowRigVedQuotes}
                  />
                </Suspense>
                <Suspense fallback={<div />}>
                  <Body angelAiUserInfo={angelAiUserInfo} />
                </Suspense>
                <Suspense fallback={<div />}>
                  <RealtorCarousel />
                </Suspense>
              
                    <Suspense fallback={<div />}>
                      <TruApproval />
                    </Suspense>
                    {showOtherComponents ? (
                  <>
                    <Suspense fallback={<div />}>
                      <AngelAi
                        aiBrandingData={aiBrandingData}
                        loanOfficerActive={false}
                        loanOfficerData={null}
                        realtorData={null}
                      />
                    </Suspense>
                    <Suspense fallback={<div />}>
                      <ProductSuite />
                    </Suspense>
                    <Suspense fallback={<div />}>
                      <UltraFastSaving aiBrandingData={aiBrandingData} />
                    </Suspense>
                    <Suspense fallback={<div />}>
                      <LoanOfficer
                        angelAiUserInfo={angelAiUserInfo}
                        aiBrandingData={aiBrandingData}
                      />
                    </Suspense>
                    <div>
                          <Image

                            src="https://d2w24n4g34usfg.cloudfront.net/realtor-cobranding/banner-image.webp"
                            srcSet="https://d2w24n4g34usfg.cloudfront.net/realtor-cobranding/banner-image.webp 375w,
                         https://d2w24n4g34usfg.cloudfront.net/realtor-cobranding/banner-image.webp 750w,
                         https://d2w24n4g34usfg.cloudfront.net/realtor-cobranding/banner-image.webp 1125w"
                            className="mobile-view-image"
                            alt="mobilefamily"
                            loading="eager"
                            width="375"
                            height="481"
                          />
                        </div>
                    <Suspense fallback={<div />}>
                      <Disclaimer aiBrandingData={aiBrandingData} />
                    </Suspense>
                    <Suspense fallback={<div />}>
                      <Footer aiBrandingData={aiBrandingData} />
                    </Suspense>
                  </>
                ) : null}
              </>
            ) : (
              ""
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default HomePage;